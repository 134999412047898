import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import "./CustomTable.css";
import jsonData from "./data/data.json";
import Button from "react-bootstrap/Button";
import CustomModal from "./Modal";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export default function ResponsiveBreakpointsExample() {
  const [tableData, setTableData] = useState("");
  const [modalState, setModalState] = useState(false);
  const [currentIndexSelected, setCurrentIndexSelected] = useState(-1);
  const [tags, setTags] = useState([]);
  const [defaultTableValue, setDefaultTableValue] = useState("");
  const [searchNames, setSearchNames] = useState("");
  const [prevTableState, setPrevTableState] = useState([]);
  const [currentSelectedTags, setCurrentSelectedTags] = useState([]);
  function showModal(index) {
    setCurrentIndexSelected(index);
    setModalState(true);
  }
  function closeModal() {
    setModalState(false);
  }

  function getTagsAndLeetcodeTitleNames() {
    let currentQuestionNames = [];
    let seenNames = new Set();
    for (let i = 0; i < tableData.data.length; i++) {
      let questionName = tableData.data[i].question;
      if (!seenNames.has(questionName)) {
        currentQuestionNames.push({
          value: questionName,
          label: questionName,
        });
        seenNames.add(questionName);
      }
     
    }
    return currentQuestionNames;
  }

  function handleSelectFilterBySearchName(selectedNames) {
    if (selectedNames == null || selectedNames.length == 0) {
      setTableData(defaultTableValue);
      return;
    }
    let temp = new Set();
    for (let row = 0; row < tableData.data.length; row++) {
      let questionName = tableData.data[row].question;
      for (let i = 0; i < selectedNames.length; i++) {
        if (questionName === selectedNames[i].value) {
          temp.add(tableData.data[row]);
        }
      }
    }
    showModal(0);

    let obj = { star: tableData.star,data: [...temp]  };
    setTableData(obj);
  }
  
  useEffect(() => {

    alert("use tab and shift tab to move silently in searching");
    let temp = loadData();
    setTableData(temp);
    setDefaultTableValue(temp);
    

  }, []);
  useEffect(() => {
    console.log("prev table state", prevTableState);
  }, [prevTableState]);

  useEffect(() => {
    console.log("table data", tableData);
    if (tableData !== "") {
      // debugger;
      let temp = getTagsAndLeetcodeTitleNames();
      setSearchNames(temp);
    }
  }, [tableData]);

  useEffect(() => {
    // console.log(tags);
  }, [tags]);

  const loadData = () => {
    return JSON.parse(JSON.stringify(jsonData));
  };
  return (
    <>
      <Container className="containerSelectStyle ">
        <Row>
          <Col>
            <Select
              options={searchNames}
              menuPlacement="bottom"
              isMulti
              className="basic-multi-select bringFront selectStyle"
              classNamePrefix="select technologies"
              closeMenuOnSelect={false}
              placeholder="Filter by Name"
              maxMenuHeight={500}
              onChange={(selectedNames) => {
                if (selectedNames == null || selectedNames.length === 0) {
                  setTableData(defaultTableValue);
                  return;
                }
                handleSelectFilterBySearchName(selectedNames);
              }}
            />
          </Col>
        </Row>
      </Container>
      {tableData && tableData.data && tableData.data.length > 0 ? (
        <div className="customcontainer overflow-scroll">
          <CustomModal
            openModal={showModal}
            closeModal={closeModal}
            data={tableData.data[currentIndexSelected]}

            show={modalState}
            typeOfModal="Answer"
            starData={tableData.star}
          />
          <Table
            variant="dark"
            className="content"
            style={{ width: "1500px" }}
            responsive="lg"
            data-sticky-header={true}
          >
            <thead>
              <tr>
                <th className="headerFontColor">Row Number</th>

                <th
                  className="headerFontColor"
                  key={tableData.data[0].question}
                >
                  question
                </th>
                <th className="headerFontColor" key={tableData.data[0].answers}>
                  answers
                </th>
              </tr>
            </thead>
            <tbody>
              {/* iterate through tableData
               */}

              {tableData.data.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {/* <td>
                    {row.leetcode_tags.map((tag, index) => (
                      <Button
                        disabled={true}
                        variant="info"
                        className="mx-2 text-white"
                        key={index}
                      >
                        {tag}
                      </Button>
                    ))}
                  </td>
           */}

                  <td>{row.question}</td>

                  <td>
                    <Button
                      onClick={() => {
                        showModal(index);
                      }}
                    >
                      Answer
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}
    </>
  );
}
