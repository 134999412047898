import "./App.css";
import CustomTable from "./components/CustomTable";
import Protect from "react-app-protect";
import "react-app-protect/dist/index.css";
import { useState, useEffect } from "react";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (isLoggedIn == false) {
      if (authentication() == true) {
        setIsLoggedIn(true);
      }
    }
  }, []);

  function authentication() {
    let password = prompt("Enter password to access this page");
    if (password !== "032901") {
      return false;
    }
    return true;
  }

  return (
    <div className="App">{isLoggedIn == true ? <CustomTable /> : null}</div>
  );
}

export default App;
