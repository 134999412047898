import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ContentEditable from "react-contenteditable";
import "./Modal.css";

export default function CustomModal({
  openModal,
  closeModal,
  data,
  show,
  typeOfModal,
  global,
  starData,
}) {
  function handleCloseModal() {
    closeModal();
  }
  useEffect(() => {
    // console.log(data.things_to_ask)
  }, [data]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        dialogClassName="modalWidth"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            
            {data? data.question : ""}
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
            <Row>
              <Col>
                {data
                  ? data.answers.map((item, index) => {
                      if (item == "") {
                        return <></>;
                      }

                      return (
                        <div
                          className="backgroundColorForContentEditable my-2"
                          key={index}
                        >
                          <ContentEditable
                            className=""
                            html={item}
                            disabled={false}
                            onChange={(e) => {
                              data[index] = e.target.value;
                            }}
                          />
                        </div>
                      );
                    })
                  : null}
              </Col>
              <Col>
                <h1>STAR Base template</h1>{" "}
                {starData ? (
                  <>
                    <img
                      width="800px"
                      height="250px"
                      src={starData.beforeAnswering}
                      alt="hello"
                    />
                    <div className="backgroundColorForContentEditable my-2">
                      <ContentEditable
                        className=""
                        html={starData.situation}
                        disabled={false}
                      />
                    </div>
                    <div className="backgroundColorForContentEditable my-2">
                      <ContentEditable
                        className=""
                        html={starData.task}
                        disabled={false}
                      />
                    </div>
                    <div className="backgroundColorForContentEditable my-2">
                      <ContentEditable
                        className=""
                        html={starData.action}
                        disabled={false}
                      />
                    </div>
                    <div className="backgroundColorForContentEditable my-2">
                      <ContentEditable
                        className=""
                        html={starData.result}
                        disabled={false}
                      />
                    </div>
                    <img
                      width="600px"
                      height="200px"
                      src={starData.afterAnswering}
                      alt="hello"
                    />
                  </>
                ) : null}
              </Col>
            </Row>
          
        </Modal.Body>
      </Modal>
    </>
  );
}
